import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import { saasModernTheme } from 'common/theme/saasModern';
import { ResetCSS } from 'common/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from 'containers/SaasModern/sassModern.style';
import SEO from 'components/seo';
export default (props) => {
  console.log('props: ', props);
  return (
    <ThemeProvider theme={saasModernTheme}>
      <Fragment>
        <SEO title="Appointment Scheduling and CRM Software" />
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper></ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
// export const query = graphql`
//   query($slug: String!) {
//     ...
//   }
// `
